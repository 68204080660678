<template>
  <div>
    <el-form ref="form" :model="contact" label-width="120px">
      <el-form-item label="联系人英文">
        <el-input v-model="contact.ContactPersonEng"></el-input>
      </el-form-item>
      <el-form-item label="联系人中文">
        <el-input v-model="contact.ContactPersonChn"></el-input>
      </el-form-item>
      <el-form-item label="英文地址">
        <el-input v-model="contact.AddressEng"></el-input>
      </el-form-item>
      <el-form-item label="中文地址">
        <el-input v-model="contact.AddressChn"></el-input>
      </el-form-item>
      <el-form-item label="邮政编号">
        <el-input v-model="contact.PostCode"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="contact.Tel"></el-input>
      </el-form-item>
      <el-form-item label="传真">
        <el-input v-model="contact.Fax"></el-input>
      </el-form-item>
      <el-form-item label="电子邮件">
        <el-input v-model="contact.Email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contact: {}
    }
  },
  created() {
    /* this.$http
      .get('/contact/getcontact')
      .then(res => (this.contact = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        }) */
        this.getcontact();
      
  },
  methods: {
    save() {
      this.$http.post('/contact/editcontact', this.contact).then(res => {
        if (res.data.code != 200) {
          this.$message.error(res.data.errmsg)
        } else {
          this.$message({
            message: '保存成功！',
            type: 'success'
          })
        }
      }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    async getcontact(){
      const {data:res} = await this.$http.get('/contact/getcontact')
      if(res.code!=200){
        return  this.$message.error(res.data.errmsg)
      }
      this.contact=res.data
    }
  }
}
</script>